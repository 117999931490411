import React, { useEffect, useState } from "react";
import SearchBar from "../components/SearchBar";
import SideBar from "../components/SideBar";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

export default function Dashboard(props) {
  const dispatch = useDispatch();
  let auth = localStorage.getItem("auth");
  let heading = "Orders";
  const [contracts, setContracts] = useState([]);

  const getOrdersForBuyer = async () => {
    await dispatch({ type: "loading", payload: true });
    await axios({
      method: "get",
      // url: `https://web3backend.meinstein.ai/api/transactions/getCompletedOrdersForBuyer`,
      url: `https://web3backend.meinstein.ai/api/transactions/getCompletedOrdersForBuyer`,
      headers: {
        "Content-Type": "application/json",
        Authorization: auth,
      },
    })
      .then(async (response) => {
        if (response.data.data) {
          await setContracts(response.data.data);
          }
        await dispatch({ type: "loading", payload: false });
      })
      .catch(async (error) => {
        await dispatch({ type: "loading", payload: false });
        console.log(error.response);
      });
  };

  useEffect(() => {
    getOrdersForBuyer();
  }, []);

  return (
    <>
      <div className="font-DMSans flex justify-end mx-auto">
        <SideBar />
        <div className="bg-[#f4f7fe] min-h-screen w-full min-[920px]:w-[75%] xl:w-[80%] 2xl:w-[85%] dark:bg-slate-700">
          <div className="mt-[31px] mx-5 text-[#2B3674] dark:text-white">
            <p className="font-Poppins lg:text-xl text-base font-bold text-end mr-5">
              Data Seller / Community
            </p>
            <SearchBar
              heading={heading}
              themeToggle={props.themeToggle}
              setThemeToggle={props.setThemeToggle}
            />
            <h3 className="text-2xl tracking-[-0.02em] font-bold mt-14">
              Previous Orders
            </h3>
            <div className="flex flex-wrap my-8">
              {contracts.length ? (
                contracts.map((data, index) => (
                  <>
                    <Link to={`/contract/download/${data.orderDetails.order_id}`} className="lg:w-[25%] md:w-[50%] w-full">
                      <div
                        key={index}
                        className="bg-white dark:bg-[#2B3674] w-[95%] mx-auto block my-2 rounded-[20px] px-6 py-6"
                      >
                        <div className="relative">
                          {data?.data_detail?.category ? <img
                            className="rounded-2xl"
                            src={`/dataImages/${data?.data_detail?.category} Data.jpg`}
                            alt="contract"
                          />
                            : <img
                              className="rounded-2xl"
                              src={`/dataImages/Multi Data.jpg`}
                              alt="contract"
                            />}
                        </div>
                        <div className="tracking-[-0.02em] leading-[30px] mt-4 ml-3 flex justify-between">
                          <div>
                            <h4 className="text-lg text-[#1B2559] dark:text-white font-bold">
                              {data.data_name}
                            </h4>
                            <p className="bg-white text-sm font-medium dark:bg-[#2B3674]">
                              Listed Date : {data.createdAt.split("T")[0]}
                            </p>
                            <p className="bg-white text-sm font-medium dark:bg-[#2B3674]">
                              Price : {data.orderDetails.price ? (parseInt(data.orderDetails.price) / 1e18).toFixed(6) : 0} BNB
                            </p>
                            <p className="text-sm uppercase text-[#A3AED0]">
                              {data.data_type}
                            </p>
                          </div>
                        </div>
                        <div className="tracking-[-0.02em] text-sm leading-6 ml-3 mt-5 flex justify-between items-center">
                          <div>
                            <p className="text-[#856DA7] dark:text-blue-600 font-bold">
                              Size (KB) : {data.data_size}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </>
                ))
              ) : (
                <p className="text-center my-10 font-semibold text-xl">
                  No Package Found
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
