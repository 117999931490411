import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchBar from "../components/SearchBar";
import SideBar from "../components/SideBar";
import { BiHide, BiShow } from "react-icons/bi";
import { CiWarning } from "react-icons/ci";
import { BsInfoCircle } from "react-icons/bs";
import axios from "axios";

export default function Profile(props) {
    const navigate = useNavigate();
    let loginUserAddress = localStorage.getItem("address");
    let loginUserId = localStorage.getItem("login");
    let heading = "Profile Information";

    const [values, setValues] = React.useState({
        email: "",
        name: "",
        lastName: "",
        password: "",
        showPassword: false,
        oldPassword: "",
        showOldPassword: false,
        confirmPassword: "",
        showConfirmPassword: false,
        wallet: ""
    });
    const [errorMsg, setError] = useState("");
    const [successMsg, setSuccessMsg] = useState("");

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const handleClickShowPassword = (e) => {
        e.preventDefault();
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleClickShowOldPassword = (e) => {
        e.preventDefault();
        setValues({ ...values, showOldPassword: !values.showOldPassword });
    };

    const handleClickShowConfirmPassword = (e) => {
        e.preventDefault();
        setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
    };

    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const getUserdata = async () => {
        if (loginUserAddress) {
            await axios({
                method: 'get',
                url: `https://web3backend.meinstein.ai/api/user/userDetails?userAddress=${loginUserAddress}`,
                headers: {
                    "Content-Type": "application/json",
                    // "Authorization": token
                }
            })
                .then(async (response) => {
                    console.log(response.data.data);
                    setValues({
                        ...values, email: response.data.data.email,
                        name: response.data.data.name,
                        wallet: response.data.data.walletAdd
                    });
                })
                .catch((error) => {
                    console.log(error.response)
                })
        }
    };

    const changePassword = async (e) => {
        e.preventDefault();
        if (values.password && values.confirmPassword && values.oldPassword) {
            if (values.password === values.confirmPassword) {
                await axios({
                    method: 'post',
                    url: `https://web3backend.meinstein.ai/api/user/ChangePassword`,
                    data: {
                        newPassword: values.password,
                        oldPassword: values.oldPassword,
                        userId: parseInt(loginUserId)
                    },
                    headers: {
                        "Content-Type": "application/json",
                        // "Authorization": token
                    }
                })
                    .then(async (response) => {
                        console.log(response.data)
                        if (response.data.status) {
                            showMessage(response.data.msg)
                        } else {
                            showErrorMessage(response.data.error)
                        }
                        setValues({
                            ...values, password: "",
                            oldPassword: "",
                            confirmPassword: ""
                        });
                    })
                    .catch((error) => {
                        console.log(error.response)
                    })
            } else showErrorMessage("New password and confirn password not match.");
        } else showErrorMessage("Please fill all the feilds.");
    };

    const updateProfile = async (e) => {
        e.preventDefault();
        if (values.password && values.confirmPassword && values.oldPassword) {
            if (values.password === values.confirmPassword) {
                await axios({
                    method: 'post',
                    url: `https://web3backend.meinstein.ai/api/user/ChangePassword`,
                    data: {
                        newPassword: values.password,
                        oldPassword: values.oldPassword,
                        userId: parseInt(loginUserId)
                    },
                    headers: {
                        "Content-Type": "application/json",
                        // "Authorization": token
                    }
                })
                    .then(async (response) => {
                        console.log(response.data)
                        if (response.data.status) {
                            showMessage(response.data.msg)
                        } else {
                            showErrorMessage(response.data.error)
                        }
                        setValues({
                            ...values, password: "",
                            oldPassword: "",
                            confirmPassword: ""
                        });
                    })
                    .catch((error) => {
                        console.log(error.response)
                    })
            } else showErrorMessage("New password and confirn password not match.");
        } else showErrorMessage("Please fill all the feilds.");
    };

    const showMessage = async (value) => {
        setSuccessMsg(value)
        setTimeout(() => {
            setSuccessMsg("");
        }, 5000)
    }
    const showErrorMessage = async (value) => {
        setError(value)
        setTimeout(() => {
            setError("");
        }, 5000)
    }

    useEffect(() => {
        getUserdata();
    }, [])

    return (
        <>
            <div className="font-DMSans flex justify-end max-w-[1920px] mx-auto">
                <SideBar />
                <div className="bg-[#f4f7fe] min-h-screen w-full min-[920px]:w-[75%] xl:w-[80%] 2xl:w-[85%] dark:bg-slate-700">
                    <div className="mt-[31px] mx-5 text-[#2B3674] dark:text-white">
                        <p className="font-Poppins lg:text-xl text-base font-bold text-end mr-5">
                            Data Seller / Community
                        </p>
                        <SearchBar heading={heading} themeToggle={props.themeToggle} setThemeToggle={props.setThemeToggle} />

                    </div>
                    <div className="my-[60px] mx-6">
                        <h2 className="font-bold text-[36px] leading-[42px] tracking-[-0.02em] text-[#2B3674] dark:text-white ">Edit your personal information</h2>
                        <form>
                            <div className="flex flex-col items-center justify-center lg:block">

                                <div className="flex lg:flex-row flex-col justify-between w-full">
                                    <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mt-6 w-full min-[920px]:w-[32%] xl:w-[32%] 2xl:w-[32%] text-[#2B3674] dark:text-white">
                                        <label>Email*</label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            className="form-control block w-full px-6 py-3 mt-2 rounded-xl text-gray-700 dark:placeholder-gray-600 bg-white dark:bg-slate-400 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                            placeholder="mail@simmmple.com"
                                        />
                                    </div>
                                    <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mt-6 w-full min-[920px]:w-[32%] xl:w-[32%] 2xl:w-[32%] text-[#2B3674] dark:text-white">
                                        <label>Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            className="form-control block w-full px-6 py-3 mt-2 rounded-xl text-gray-700 dark:placeholder-gray-600 bg-white dark:bg-slate-400 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                            placeholder="Username"
                                        />
                                    </div>
                                    <div className="w-full min-[920px]:w-[33%] xl:w-[33%] 2xl:w-[33%]"></div>
                                </div>
                                <div className="flex lg:flex-row flex-col justify-between w-full">
                                    <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mt-6 w-full min-[920px]:w-[32%] xl:w-[32%] 2xl:w-[32%] text-[#2B3674] dark:text-white">
                                        <label>Wallet</label>
                                        <input
                                            type="text"
                                            name="wallet"
                                            value={values.wallet}
                                            disabled={true}
                                            onChange={handleChange}
                                            className="form-control block w-full px-6 py-3 mt-2 rounded-xl text-gray-700 dark:placeholder-gray-600 bg-white dark:bg-slate-400 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                            placeholder="0x0"
                                        />
                                    </div>
                                    <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mt-6 w-full min-[920px]:w-[32%] xl:w-[32%] 2xl:w-[32%] text-[#2B3674] dark:text-white">
                                        <label>Last Name</label>
                                        <input
                                            type="text"
                                            name="lastName"
                                            value={values.lastName}
                                            onChange={handleChange}
                                            className="form-control block w-full px-6 py-3 mt-2 rounded-xl text-gray-700 dark:placeholder-gray-600 bg-white dark:bg-slate-400 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                            placeholder="Cris"
                                        />
                                    </div>
                                    <div className="w-full min-[920px]:w-[33%] xl:w-[33%] 2xl:w-[33%]"></div>
                                </div>

                                <div className="w-full min-[920px]:w-[32%] xl:w-[32%] 2xl:w-[32%] text-center text-xs sm:text-sm mt-16">
                                    <button
                                        className="flex justify-center items-center w-full tracking-[-0.02em] font-bold text-white bg-[#856DA7] dark:bg-[#2B3674] rounded-2xl py-4 sm:my-5"
                                    >
                                        Edit Profile
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {errorMsg
                ? <div className="w-full fixed z-50 top-5 mx-5 right-0 max-w-sm p-4 text-white bg-red-600 rounded-2xl">
                    <div className="flex items-center">
                        <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8">
                            <CiWarning className="inline" size={25} />
                        </div>
                        <div className="ml-3 text-sm font-normal">
                            <span className=" text-sm font-semibold text-white">{errorMsg}</span>
                        </div>
                    </div>
                </div>
                : null
            }

            {successMsg
                ? <div className="w-full fixed z-50 top-5 mx-5 right-0 max-w-sm p-4 text-white bg-green-600 rounded-2xl">
                    <div className="flex items-center">
                        <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8">
                            <BsInfoCircle className="inline" size={25} />
                        </div>
                        <div className="ml-3 text-sm font-normal">
                            <span className=" text-sm font-semibold text-white">{successMsg}</span>
                        </div>
                    </div>
                </div>
                : null
            }
        </>
    );
}
