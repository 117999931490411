import React, { useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import axios from "axios";
import {
  useAccount,
  useConnect
} from "wagmi";
import { Dispute } from "../contractIntreaction";
import { useDispatch } from "react-redux";
import Web3 from "web3";

export default function Table() {
  let userId = localStorage.getItem("login");
  const dispatch = useDispatch();
  const [transactions, setTransactions] = useState([]);
  const [errorMsg, setError] = useState("");
  const [currentOrderID, setCurrentOrderID] = useState("");
  const [showDisputeModal, setShowDisputeModal] = useState(false);
  const [isBuyer, setIsBuyer] = useState();

  const { address, connector, isConnected } = useAccount();
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect();

  const connectAndSign = async (x) => {
    await connect(x);
  }

  const checkType = async () => {
    let userType = await localStorage.getItem("type");
    if (userType == "Buyer") setIsBuyer(true);
    else setIsBuyer(false);
  };

  useEffect(() => {
    checkType();
  }, []);

  const getTransactionPackages = async () => {
    if (userId) {
      await dispatch({ type: "loading", payload: true });
      await axios({
        method: 'get',
        url: `https://web3backend.meinstein.ai/api/transactions?userID=${userId}`,
        headers: {
          "Content-Type": "application/json",
          // "Authorization": token
        }
      })
        .then(async (response) => {
          console.log(response.data);
          if (response.data.data) await setTransactions(response.data.data);
          await dispatch({ type: "loading", payload: false });
        })
        .catch(async (error) => {
          console.log(error.response)
          await dispatch({ type: "loading", payload: false });
        })
    }
  };

  useEffect(() => {
    getTransactionPackages();
  }, [])

  return (
    <>
      <div className="mt-[31px] mx-5">
        <p className="font-Poppins lg:text-xl text-base font-bold text-[#2B3674] dark:text-white text-end mr-5">
          Data Seller / Community
        </p>

        <div className="bg-white dark:bg-[#2B3674] w-full rounded-[20px] px-8 py-5 mt-10">
          <div className="flex justify-between items-center">
            <h3 className="text-2xl text-[#1B2559] dark:text-white font-bold">{isBuyer ? "Transactions" : "Orders"}</h3>
          </div>

          <div className="mt-5 overflow-x-auto">
            <div className="border-b border-[#E9EDF7] flex items-center justify-between pb-2">
              <div className="min-w-[840px]">
                <div className="w-full flex justify-around text-[#A3AED0] tracking-[-0.02em] leading-6 text-sm font-medium ml-3">
                  <div className="flex items-center">
                    <p>Type</p>
                    <FiChevronDown className="text-lg ml-2" />
                  </div>

                  <div className="flex items-center">
                    <p>Order ID </p>
                    <FiChevronDown className="text-lg ml-2" />
                  </div>

                  <div className="flex items-center">
                    <p>Price (BNB) </p>
                    <FiChevronDown className="text-lg ml-2" />
                  </div>

                  <div className="flex items-center">
                    <p>Fees (BNB)</p>
                    <FiChevronDown className="text-lg ml-2" />
                  </div>

                  <div className="flex items-center">
                    <p>Status</p>
                    <FiChevronDown className="text-lg ml-2" />
                  </div>

                  <div className="flex items-center">
                    <p>Date</p>
                    <FiChevronDown className="text-lg ml-2" />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-[1050px]">
              <table className="table-fixed border-separate border-spacing-y-2 w-full">
                <tbody className="text-[#2B3674] dark:text-white tracking-[-0.02em] leading-6 text-sm font-bold text-center">
                  {transactions.length
                    ? <>
                      {
                        transactions.map((data, index) => (
                          <tr key={index} className="flex">
                            <td className="w-[13%]">{data.seller === address ? "SELL" : ""}{data.buyer === address ? "BUY" : ""}</td>
                            <td className="w-[13%]">{data.order_id}</td>
                            <td className="w-[15%]">{data.price / 1e18}</td>
                            <td className="w-[16%]">{Web3.utils.fromWei(data.fee.toString(), 'ether')}</td>
                            <td className="w-[10%]">{data.status}</td>
                            <td className="w-[16%]">{data.updatedAt.split('T')[0]}</td>
                            <td className="w-[10%]">
                              <a href={`https://testnet.bscscan.com/tx/${data.trx}`} target="_blank" className="flex bg-[#11047A] text-white font-medium h-[30px] items-center justify-center cursor-pointer w-[93px] rounded-full" rel="noreferrer">
                                Detail
                              </a>
                            </td>
                            <td className="w-[8%]">
                              {/* <button className="flex bg-[#11047A] text-white font-medium h-[30px] items-center justify-center cursor-pointer w-[93px] rounded-full"
                              onClick={()=>setShowDisputeModal(true)} >
                                Dispute
                              </button> */}
                            </td>
                          </tr>
                        ))}
                    </>
                    : <p className="text-center font-semibold py-6 w-full text-[#161319] dark:text-white">No Transaction Found</p>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {showDisputeModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none modalOuter">
            <div className="relative w-auto my-6 mx-auto max-w-sm">
              {/*content*/}
              <div className="border-0 w-[350px] text-black font-saira rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between py-2 px-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl mb-0 font-semibold">Dispute</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowDisputeModal(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  {isConnected
                    ? <div className="flex justify-center">
                      <Dispute orderId={currentOrderID} />
                    </div>
                    : <div className="flex justify-center items-center">
                      <>
                        {connectors
                          .filter((x) => x.ready && x.id !== connector?.id)
                          .map((x) => (
                            <>
                              {x.name === "MetaMask" ? (
                                <button type="button" className="bg-[#11047A] text-white font-medium h-[30px] px-5 rounded-full"
                                  key={x.id}
                                  onClick={async () => {
                                    await connectAndSign({ connector: x });
                                  }}
                                >
                                  Connect MetaMask
                                  {isLoading &&
                                    x.id === pendingConnector?.id &&
                                    " (connecting)"}
                                </button>
                              ) : null}
                            </>
                          ))
                        }
                        {error ? <p className="text-sm text-center my-3 text-red-700 font-semibold">{error}</p> : null}
                        {connectors.filter((x) => x.ready && x.id !== connector?.id).length
                          ? null
                          : <p className="text-sm text-center text-red-700 font-semibold"><a className="text-[#856DA7]" href="https://metamask.io/download/" target="_blank"> Install Metamask</a></p>
                        }
                      </>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
