import React, { useState, useEffect } from "react";
import { MdNightlight } from "react-icons/md";
import { BiShow, BiHide } from "react-icons/bi";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { BsCheck2Circle } from "react-icons/bs";
import { useSignMessage, useAccount, useConnect } from "wagmi";
import axios from "axios";
import { countries } from "../data/countries";
import { Business } from "../data/bussiness";

export default function BuyerSignUp(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [values, setValues] = React.useState({
    userId: "",
    auth: "",
    otp: "",
    signIn: "",
    email: "",
    password: "",
    userAddress: "",
    address: "",
    firstName: "",
    lastName: "",
    countryCode: "",
    phoneNumber: "",
    gender: "",
    zipCode: "",
    dob: "",
    longitude: 0,
    latitude: 0,
    Is_agree: "",
    industries: [],
    isDocVerified: true,
    showPassword: false,
    verifyAddress: false,
    verifyIdentity: false,
    verifyName: false,
    verifyBank: false,
  });
  const [tab, setTab] = useState(3);
  const [errorMsg, setError] = useState("");
  const [showAllIndustries, setShowAllIndustries] = useState(false);

  const { address, connector, isConnected } = useAccount();
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect();
  const { data, signMessage } = useSignMessage({
    message: `This is to verify that you are the actual owner of ${address ? address : null
      }`,
  });

  const handleClickShowPassword = (e) => {
    e.preventDefault();
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleIndustries = (e) => {
    let array = values.industries;
    let value = parseInt(e.target.value);
    const index = array.indexOf(value);

    if (index === -1) array.push(value);
    else array.splice(index, 1);

    setValues({ ...values, industries: array });
  };

  const connectAndSign = async (x) => {
    await connect(x);
  };

  const signatureFunc = async () => {
    if (isConnected && (!values.signIn || values.signIn == "undefined")) {
      await signMessage();
    }
  };

  const tab2Change = async () => {
    if (values.email) {
      await sessionStorage.setItem("userEmail", values.email);
      await setTab(2);
    } else setError("Please enter email first");
  };

  const tab3Change = async () => {
    if (values.email) {
      // await sessionStorage.setItem("userEmail", values.email);
      await setTab(3);
    } else setError("Please enter email first");
  };

  const getLocation = async () => {
    if (navigator.geolocation)
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    else console.error("Geolocation is not supported by this browser.");
  };

  useEffect(() => {
    if (data) setValues({ ...values, signIn: data });
  }, [data]);

  useEffect(() => {
    setDatainSession();
  }, [location.search]);

  useEffect(() => {
    getDatafromSession();
  }, []);

  const getDatafromSession = async () => {
    let sessionEmail = await sessionStorage.getItem("userEmail");
    let sessionverifiedAddress = await sessionStorage.getItem("verifyAddress");
    let sessionverifiedName = await sessionStorage.getItem("verifyName");
    let sessionverifiedIdentity = await sessionStorage.getItem(
      "verifyIdentity"
    );
    let sessionverifiedBank = await sessionStorage.getItem("verifyBank");
    if (sessionEmail) setTab(2);
    await setValues({
      ...values,
      email: sessionEmail ? sessionEmail : "",
      verifyAddress: sessionverifiedAddress ? sessionverifiedAddress : "",
      verifyName: sessionverifiedName ? sessionverifiedName : "",
      verifyIdentity: sessionverifiedIdentity ? sessionverifiedIdentity : "",
      verifyBank: sessionverifiedBank ? sessionverifiedBank : "",
    });
    await getLocation();
  };

  const setDatainSession = async () => {
    const queryParams = new URLSearchParams(location.search);
    const va = await queryParams.get("va");
    const vb = await queryParams.get("vb");
    const vn = await queryParams.get("vn");
    const vi = await queryParams.get("vi");

    if (va) await sessionStorage.setItem("verifyAddress", va);
    if (vn) await sessionStorage.setItem("verifyName", vn);
    if (vi) await sessionStorage.setItem("verifyIdentity", vi);
    if (vb) await sessionStorage.setItem("verifyBank", vb);

    await getDatafromSession();
  };

  const successCallback = async (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    let sessionEmail = await sessionStorage.getItem("userEmail");
    let sessionverifiedAddress = await sessionStorage.getItem("verifyAddress");
    let sessionverifiedName = await sessionStorage.getItem("verifyName");
    let sessionverifiedIdentity = await sessionStorage.getItem(
      "verifyIdentity"
    );
    let sessionverifiedBank = await sessionStorage.getItem("verifyBank");

    setValues({
      ...values,
      email: sessionEmail ? sessionEmail : "",
      verifyAddress: sessionverifiedAddress ? sessionverifiedAddress : "",
      verifyName: sessionverifiedName ? sessionverifiedName : "",
      verifyIdentity: sessionverifiedIdentity ? sessionverifiedIdentity : "",
      verifyBank: sessionverifiedBank ? sessionverifiedBank : "",
      latitude: latitude,
      longitude: longitude,
    });
  };

  const errorCallback = async (error) => {
    console.error("Error getting the current position:", error.message);
  };

  const toMain = async (e) => {
    e.preventDefault();
    // if (isConnected) {
    if (
      values.firstName &&
      values.email &&
      values.password &&
      values.address &&
      values.lastName &&
      values.countryCode &&
      values.phoneNumber &&
      values.gender &&
      values.zipCode &&
      values.dob
    ) {
      await axios({
        method: "post",
        url: `https://web3backend.meinstein.ai/api/auth/register`,
        data: {
          email: values.email,
          password: values.password,
          address: values.address,
          // signature: "random",
          firstName: values.firstName,
          lastName: values.lastName,
          countryCode: values.countryCode,
          phoneNumber: values.phoneNumber,
          gender: values.gender,
          zipCode: values.zipCode,
          dob: values.dob,
          longitude: values.longitude,
          latitude: values.latitude,
          I_got_it: "true",
          Is_agree: "true",
          // userAddress: 'address',
          user_type: "BUYER",
          industries: values.industries,
        },
        headers: {
          "Content-Type": "application/json",
          isdocverified: true,
        },
      })
        .then(async (response) => {
          if (response.data.success) {
            setValues({
              ...values,
              userId: response.data.data.data.user_id,
              auth: response.data.data.data.auth_token,
            });
            setTab(4);
          } else {
            setError(response.data?.error);
            setTimeout(() => {
              setError("");
            }, 5000);
          }
        })
        .catch((error) => {
          setError(error.response.data.errorMessage);
          setTimeout(() => {
            setError("");
          }, 5000);
        });
    } else setError("Please fill all the feilds");
    // } else setError("Please connect metamask first");
  };

  const otpVerification = async (e) => {
    e.preventDefault();
    // if (isConnected) {
    if (values.otp && values.userId) {
      await axios({
        method: "post",
        url: `https://web3backend.meinstein.ai/api/auth/verify`,
        data: {
          user_id: values.userId.toString(),
          otp: values.otp,
        },
        headers: {
          "Content-Type": "application/json",
          isdocverified: true,
        },
      })
        .then(async (response) => {
          console.log(response);
          if (response.data.data.status) {
            await localStorage.setItem("login", response.data.data.data.user_id)
            await localStorage.setItem("auth", response.data.data.data.token)
            await localStorage.setItem("address", address)
            await localStorage.setItem("type", 'Buyer')
            await navigate("/dashboard");
          } else {
            setError(response.data?.error);
            setTimeout(() => {
              setError("");
            }, 5000);
          }
        })
        .catch((error) => {
          console.log(error.response?.data?.errorMessage)
          setError(error.response?.data?.errorMessage.toString());
          setTimeout(() => {
            setError("");
          }, 5000);
        });
    } else setError("Please fill all the feilds");
    // } else setError("Please connect metamask first");
  };

  return (
    <>
      <div className="bg-white dark:bg-[#2B3674] font-DMSans">
        <div className="flex h-[95vh] bg-[url('img/background.png')] bg-cover lg:bg-none">
          <div className="w-11/12 sm:w-3/4 mx-auto lg:w-1/2">
            <section className="h-[96%] lg:h-full flex flex-col justify-center lg:block">
              <div className="px-6 h-5/6 sm:h-2/3 lg:h-[96%] text-gray-800">
                <img
                  src="/img/logo.jpg"
                  alt=""
                  className="w-[70px] mt-10 rounded-2xl"
                />
                <div className="flex justify-center items-center flex-wrap h-[75%] overflow-y-scroll pb-[150px] pt-[50px] scrollAdjust g-6 bg-white dark:bg-[#2B3674] rounded-tl-[90px] rounded-br-[90px] lg:bg-transparent lg:rounded-none">
                  <div className="sm:w-[410px]">
                    <form>
                      <div className="flex flex-col items-center justify-center lg:block">
                        <p className="text-2xl sm:text-[36px] sm:w-full font-bold text-[#2B3674] dark:text-white mb-8">
                          Sign Up for Buyer
                        </p>

                        {tab === 1 ? (
                          <>
                            <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                              <label>Email*</label>
                              <input
                                type="email"
                                name="email"
                                onChange={handleChange}
                                value={values.email}
                                className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                placeholder="mail@simmmple.com"
                              />
                            </div>

                            <div className="w-full text-center text-xs sm:text-sm mt-10">
                              <button
                                type="button"
                                onClick={tab2Change}
                                className="flex justify-center items-center w-full tracking-[-0.02em] font-bold text-white bg-[#856DA7] rounded-2xl py-5 sm:my-5"
                              >
                                Next
                              </button>
                            </div>
                          </>
                        ) : null}
                        {tab === 2 ? (
                          <>
                            {values.email ? (
                              <>
                                {!values.verifyAddress ? (
                                  <a
                                    href={`https://diro.io/verification?buttonid=O.PK-WkiFc0-YxV&trackid=${values.email}`}
                                    className="flex justify-center items-center w-full tracking-[-0.02em] font-bold text-white bg-[#856DA7] rounded-2xl py-5 sm:my-5"
                                  >
                                    Verify Address
                                  </a>
                                ) : (
                                  <p className="flex justify-center items-center w-full tracking-[-0.02em] font-bold text-white bg-green-600 rounded-2xl py-2 sm:my-5">
                                    <BsCheck2Circle
                                      size={30}
                                      className="mx-5"
                                    />{" "}
                                    Address Verified
                                  </p>
                                )}
                                {!values.verifyName ? (
                                  <a
                                    href={`https://diro.io/verification?buttonid=O.PK-WkiFc0-qxU&trackid=${values.email}`}
                                    className="flex justify-center items-center w-full tracking-[-0.02em] font-bold text-white bg-[#856DA7] rounded-2xl py-5 sm:my-5"
                                  >
                                    Verify Name
                                  </a>
                                ) : (
                                  <p className="flex justify-center items-center w-full tracking-[-0.02em] font-bold text-white bg-green-600 rounded-2xl py-2 sm:my-5">
                                    <BsCheck2Circle
                                      size={30}
                                      className="mx-5"
                                    />{" "}
                                    Name Verified
                                  </p>
                                )}
                                {!values.verifyIdentity ? (
                                  <a
                                    href={`https://diro.io/verification?buttonid=O.PK-WkiFc0-CB9&trackid=${values.email}`}
                                    className="flex justify-center items-center w-full tracking-[-0.02em] font-bold text-white bg-[#856DA7] rounded-2xl py-5 sm:my-5"
                                  >
                                    Verify Identity
                                  </a>
                                ) : (
                                  <p className="flex justify-center items-center w-full tracking-[-0.02em] font-bold text-white bg-green-600 rounded-2xl py-2 sm:my-5">
                                    <BsCheck2Circle
                                      size={30}
                                      className="mx-5"
                                    />{" "}
                                    Identity Verified
                                  </p>
                                )}
                                {!values.verifyBank ? (
                                  <a
                                    href={`https://diro.io/verification?buttonid=O.PK-WkiFc0-98a&trackid=${values.email}`}
                                    className="flex justify-center items-center w-full tracking-[-0.02em] font-bold text-white bg-[#856DA7] rounded-2xl py-5 sm:my-5"
                                  >
                                    Verify Bank
                                  </a>
                                ) : (
                                  <p className="flex justify-center items-center w-full tracking-[-0.02em] font-bold text-white bg-green-600 rounded-2xl py-2 sm:my-5">
                                    <BsCheck2Circle
                                      size={30}
                                      className="mx-5"
                                    />{" "}
                                    Bank Verified
                                  </p>
                                )}
                                <div className="w-full text-center text-xs sm:text-sm mt-10">
                                  <button
                                    type="button"
                                    onClick={tab3Change}
                                    className="flex justify-center items-center w-full tracking-[-0.02em] font-bold text-white bg-[#856DA7] rounded-2xl py-5 sm:my-5"
                                  >
                                    Next
                                  </button>
                                </div>
                              </>
                            ) : null}
                          </>
                        ) : null}
                        {tab === 3 ? (
                          <>
                            <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                              <label>First Name*</label>
                              <input
                                type="text"
                                name="firstName"
                                onChange={handleChange}
                                value={values.firstName}
                                className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                placeholder="Enter first name"
                              />
                            </div>
                            <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                              <label>Last Name*</label>
                              <input
                                type="text"
                                name="lastName"
                                onChange={handleChange}
                                value={values.lastName}
                                className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                placeholder="Enter last name"
                              />
                            </div>
                            <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                              <label>Date of Birth*</label>
                              <input
                                type="date"
                                name="dob"
                                onChange={handleChange}
                                value={values.dob}
                                className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                              />
                            </div>
                            <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                              <label>Email*</label>
                              <input
                                type="email"
                                name="email"
                                onChange={handleChange}
                                value={values.email}
                                className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                placeholder="mail@simmmple.com"
                              />
                            </div>
                            <div className=" mb-6 mt-3 sm:mt-0 text-xs sm:text-sm font-medium">
                              <p>Gender*</p>
                              <div className="flex space-x-6 mt-3">
                                <label className="flex space-x-2 items-center">
                                  <input
                                    type="radio"
                                    name="gender"
                                    value="M"
                                    checked={values.gender === "M"}
                                    onChange={handleChange}
                                  />
                                  <span>Male</span>
                                </label>
                                <label className="flex space-x-2 items-center">
                                  <input
                                    type="radio"
                                    name="gender"
                                    value="F"
                                    checked={values.gender === "F"}
                                    onChange={handleChange}
                                  />
                                  <span>Female</span>
                                </label>
                              </div>
                            </div>
                            <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                              <label>Number*</label>
                              <div className="flex space-x-3">
                                <select
                                  name="countryCode"
                                  onChange={handleChange}
                                  value={values.countryCode}
                                  className="form-control block w-[25%] px-1 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                >
                                  <option value="" selected disabled>
                                    Select your country code
                                  </option>
                                  {countries.length &&
                                    countries.map((data, index) => (
                                      <option
                                        key={index}
                                        value={data.dial_code}
                                      >
                                        {data.code} {data.dial_code}
                                      </option>
                                    ))}
                                </select>
                                <input
                                  type="number"
                                  name="phoneNumber"
                                  onChange={handleChange}
                                  value={values.phoneNumber}
                                  className="form-control block w-[75%] px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                />
                              </div>
                            </div>
                            <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white relative">
                              <label>Industries*</label>
                              <button
                                type="button"
                                onClick={() =>
                                  setShowAllIndustries(!showAllIndustries)
                                }
                                className="form-control block w-full px-2 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                              >
                                {values.industries.length ? (
                                  <>
                                    <div className="flex space-x-1 flex-wrap space-y-1">
                                      {values.industries &&
                                        values.industries.map((data, index) => (
                                          <p
                                            key={index}
                                            className="flex justify-center items-center px-3 tracking-[-0.02em] font-bold text-white bg-[#856DA7] rounded-xl py-1"
                                          >
                                            {Business[data]}
                                          </p>
                                        ))}
                                    </div>
                                  </>
                                ) : (
                                  "Select Industries"
                                )}
                              </button>
                              <div
                                className={`w-full  px-6 py-2 mt-2 rounded-xl dark:text-gray-700 text-gray-200 dark:bg-white bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none absolute ${showAllIndustries ? "block" : "hidden"
                                  }`}
                              >
                                <ul className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200 h-[400px] overflow-y-scroll">
                                  {Business.length &&
                                    Business.map((data, index) => (
                                      <>
                                        <li>
                                          <div className="flex items-center p-2 ">
                                            <input
                                              id={`checkbox-item-${index}`}
                                              type="checkbox"
                                              checked={values.industries.includes(
                                                index
                                              )}
                                              onChange={handleIndustries}
                                              value={index}
                                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                            />
                                            <label
                                              for={`checkbox-item-${index}`}
                                              className="w-full ms-2 text-sm dark:text-gray-700 text-gray-200"
                                            >
                                              {data}
                                            </label>
                                          </div>
                                        </li>
                                      </>
                                    ))}
                                </ul>
                              </div>
                            </div>
                            <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                              <label>Zip Code*</label>
                              <input
                                type="text"
                                name="zipCode"
                                onChange={handleChange}
                                value={values.zipCode}
                                className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                placeholder="Enter zip code"
                              />
                            </div>
                            <div className="text-xs sm:text-sm mb-6 w-full font-medium leading-[14px] tracking-[-0.02em] text-[#2B3674] dark:text-white">
                              <label>Password*</label>
                              <div className="flex items-center justify-center mt-2 border border-solid border-gray-300 bg-white dark:border-none dark:bg-slate-700 rounded-xl">
                                <input
                                  type={
                                    values.showPassword ? "text" : "password"
                                  }
                                  onChange={handleChange}
                                  value={values.password}
                                  name="password"
                                  className="form-control block w-[90%] rounded-xl px-4 py-2 text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                  placeholder="Min. 8 characters"
                                />
                                <button
                                  onClick={handleClickShowPassword}
                                  className="text-[#A3AED0]"
                                >
                                  {values.showPassword ? (
                                    <BiHide size={20} />
                                  ) : (
                                    <BiShow size={20} />
                                  )}
                                </button>
                              </div>
                            </div>
                            <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                              <label>Address*</label>
                              <textarea
                                name="address"
                                rows="4"
                                onChange={handleChange}
                                value={values.address}
                                className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                              />
                            </div>

                            <div className="w-full text-center text-xs sm:text-sm mt-10">
                              {/* {isConnected &&
                              (!values.signIn ||
                                values.signIn == "undefined") ? (
                                <button
                                  type="button"
                                  onClick={() => signatureFunc()}
                                  className="flex justify-center items-center w-full tracking-[-0.02em] font-bold text-white bg-[#856DA7] rounded-2xl py-5 sm:my-5"
                                >
                                  Sign Message
                                </button>
                              ) : isConnected ? ( */}
                              <button
                                onClick={toMain}
                                className="flex justify-center items-center w-full tracking-[-0.02em] font-bold text-white bg-[#856DA7] rounded-2xl py-5 sm:my-5"
                              >
                                Sign Up
                              </button>
                              {/* ) : (
                                <>
                                  {connectors
                                    .filter(
                                      (x) => x.ready && x.id !== connector?.id
                                    )
                                    .map((x) => (
                                      <>
                                        {x.name == "MetaMask" ? (
                                          <button
                                            type="button"
                                            className="flex justify-center items-center w-full tracking-[-0.02em] font-bold text-white bg-[#856DA7] rounded-2xl py-5 sm:my-5"
                                            key={x.id}
                                            onClick={async () => {
                                              await connectAndSign({
                                                connector: x,
                                              });
                                            }}
                                          >
                                            Sign in with MetaMask
                                            {isLoading &&
                                              x.id === pendingConnector?.id &&
                                              " (connecting)"}
                                          </button>
                                        ) : null}
                                      </>
                                    ))}
                                  {connectors.filter(
                                    (x) => x.ready && x.id !== connector?.id
                                  ).length ? null : (
                                    <p className="text-sm text-center text-red-700 font-semibold">
                                      <a
                                        className="text-[#856DA7]"
                                        href="https://metamask.io/download/"
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {" "}
                                        Install Metamask
                                      </a>
                                    </p>
                                  )}
                                </>
                              )} */}
                            </div>
                          </>
                        ) : null}
                        {tab === 4 ? (
                          <>
                            <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                              <label>OTP*</label>
                              <input
                                type="number"
                                name="otp"
                                onChange={handleChange}
                                value={values.otp}
                                className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                placeholder="Enter OTP code"
                              />
                            </div>

                            <div className="w-full text-center text-xs sm:text-sm mt-10">
                              <button
                                type="button"
                                onClick={otpVerification}
                                className="flex justify-center items-center w-full tracking-[-0.02em] font-bold text-white bg-[#856DA7] rounded-2xl py-5 sm:my-5"
                              >
                                Verify
                              </button>
                            </div>
                          </>
                        ) : null}

                        <div>
                          {errorMsg ? (
                            <p className="text-sm text-center text-red-700 font-semibold">
                              {errorMsg}
                            </p>
                          ) : null}
                          <p className="text-start mt-10 text-[#2B3674] tracking-[-0.02em] leading-[26px] dark:text-gray-400">
                            Already have buyer account?
                            <Link
                              to="/"
                              className="pl-1 text-[#856DA7] hover:text-[#856DA7] focus:text-[#856DA7] font-semibold transition duration-200 ease-in-out"
                            >
                              Sign In
                            </Link>
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="hidden lg:flex flex-col items-center justify-center bg-cover lg:bg-[url('img/background.png')] rounded-[30px] mr-2 w-1/2 lg:space-y-10 space-y-7 text-gray-200 relative">
            <p className="w-3/4 font-extrabold text-center text-5xl leading-[60px] tracking-wide">
              THE NEW WAY TO CONNECT WITH CONSUMERS
            </p>
            <p className="w-3/4 font-light text-center text-2xl absolute bottom-24 uppercase">
              Purchase consent-based data & intelligence directly from
              consumers.
            </p>
          </div>
          <button className="fixed bottom-16 z-50 right-7" onClick={() => props.setThemeToggle(!props.themeToggle)}>
            <div className="theme_toggler p-3 text-white -rotate-45">
              <MdNightlight className="text-sm sm:text-xl" />
            </div>
          </button>
        </div>
      </div>
    </>
  );
}
