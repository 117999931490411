import React, { useState, useEffect, useRef } from "react";
import { BuyDataPackage } from "../contractIntreaction";
import { useAccount } from "wagmi";

export default function TopContracts(props) {
  const userId = localStorage.getItem("login");
  const { address, isConnected } = useAccount();
  const [isOpenAge, setIsOpenAge] = useState(false);
  const [isOpenGender, setIsOpenGender] = useState(false);
  const dropdownAgeRef = useRef(null);
  const dropdownGenderRef = useRef(null);
  const [filters, setFilters] = useState({
    country: "",
    zip_code: "",
    state: "",
    age: [],
    gender: [],
  });

  const applyFilters = () => {
    const filteredContracts = props.contracts.filter((contract) => {
      const country = !filters.country || (
        contract?.data_detail?.country &&
        new RegExp(filters.country, 'i').test(contract.data_detail.country)
      );

      const zip_code = !filters.zip_code || (
        contract?.data_detail?.zipcode &&
        new RegExp(filters.zip_code, 'i').test(contract.data_detail.zipcode)
      );

      const state = !filters.state || (
        contract?.data_detail?.state &&
        new RegExp(filters.state, 'i').test(contract.data_detail.state)
      );

      const agePass = filters.age.length === 0 || (
        contract?.data_detail?.age &&
        filters.age.some(ageRange => {
          const [minAge, maxAge] = ageRange.split('-').map(Number);
          const contractAge = Number(contract.data_detail.age);
          return contractAge >= minAge && contractAge <= maxAge;
        })
      );

      const genderPass = filters.gender.length === 0 || (
        contract?.data_detail?.gender &&
        filters.gender.includes(contract?.data_detail?.gender)
      );

      return agePass && genderPass && country && zip_code && state;
    });

    return filteredContracts;
  };

  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      if (checked) {
        setFilters({
          ...filters,
          [name]: [...filters[name], value],
        });
      } else {
        setFilters({
          ...filters,
          [name]: filters[name].filter((data) => data !== value),
        });
      }
    } else {
      // For other inputs like minPrice and maxPrice, handle as before
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownAgeRef.current && !dropdownAgeRef.current.contains(event.target)) {
        setIsOpenAge(false);
      }
      if (dropdownGenderRef.current && !dropdownGenderRef.current.contains(event.target)) {
        setIsOpenGender(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="flex lg:justify-end md:justify-center justify-center">
        <div className="bg-white dark:bg-slate-700 border border-solid border-gray-300 dark:border-none lg:inline-flex md:lg:inline-flex flex items-center rounded-xl lg:flex-row md:flex-row flex-col flex-wrap mt-5 lg:mt-0 md:mt-0 pb-3 lg:pb-0 md:pb-0">
          <input
            type="text"
            name="country"
            value={filters.country}
            onChange={handleFilterChange}
            placeholder="Country"
            className="form-control block w-[200px] px-6 py-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          />
          <input
            type="text"
            name="zip_code"
            value={filters.zip_code}
            onChange={handleFilterChange}
            placeholder="Zip Code"
            className="form-control block w-[200px] px-6 py-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          />
          <input
            type="text"
            name="state"
            value={filters.state}
            onChange={handleFilterChange}
            placeholder="State"
            className="form-control block w-[200px] px-6 py-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          />
          <div ref={dropdownAgeRef} className="relative cursor-pointer">
            <p className="block  lg:w-[200px] md:w-[200px] w-full px-6 py-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border-none transition ease-in-out m-0 " onClick={() => setIsOpenAge(!isOpenAge)}>Age</p>
            {isOpenAge &&
              <div className="absolute z-40 bg-white top-11 p-4 shadow rounded-2xl">
                <ul>
                  <li> <input className="mr-2" name="age" onChange={handleFilterChange} type="checkbox" checked={filters.age.includes("13-17")} value="13-17" />13-17 years</li>
                  <li> <input className="mr-2" name="age" onChange={handleFilterChange} type="checkbox" checked={filters.age.includes("18-24")} value="18-24" />18-24 years</li>
                  <li> <input className="mr-2" name="age" onChange={handleFilterChange} type="checkbox" checked={filters.age.includes("25-34")} value="25-34" />25-34 years</li>
                  <li> <input className="mr-2" name="age" onChange={handleFilterChange} type="checkbox" checked={filters.age.includes("25-44")} value="25-44" />25-44 years</li>
                  <li> <input className="mr-2" name="age" onChange={handleFilterChange} type="checkbox" checked={filters.age.includes("35-54")} value="35-54" />35-54 years</li>
                  <li> <input className="mr-2" name="age" onChange={handleFilterChange} type="checkbox" checked={filters.age.includes("55-64")} value="55-64" />55-64 years</li>
                  <li> <input className="mr-2" name="age" onChange={handleFilterChange} type="checkbox" checked={filters.age.includes("65")} value="65" />65 and above</li>
                </ul>
              </div>
            }
          </div>
          <div ref={dropdownGenderRef} className="relative cursor-pointer">
            <p className="block  lg:w-[200px] md:w-[200px] w-full px-6 py-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border-none transition ease-in-out m-0 " onClick={() => setIsOpenGender(!isOpenGender)}>Gender</p>
            {isOpenGender &&
              <div className="absolute z-40 bg-white top-11 p-4 shadow rounded-2xl">
                <ul>
                  <li> <input className="mr-2" name="gender" onChange={handleFilterChange} type="checkbox" checked={filters.gender.includes("M")} value="M" />Male</li>
                  <li> <input className="mr-2" name="gender" onChange={handleFilterChange} type="checkbox" checked={filters.gender.includes("F")} value="F" />Female</li>
                </ul>
              </div>
            }
          </div>
          <button
            className="bg-[#11047A] text-white font-medium mx-2 h-[30px] w-[93px] rounded-xl"
          >
            Search
          </button>
        </div>
      </div>
      <div className="flex flex-wrap my-8">
        {applyFilters().length ? (
          applyFilters().map((data, index) => (
            !data.orderDetails.interested_user.length || (data.orderDetails.interested_user.length && data.orderDetails.interested_user[0]?.userId == userId)
            ?<>
              <div className="lg:w-[25%] md:w-[50%] w-full">
                <div
                  key={index}
                  className="bg-white dark:bg-[#2B3674] w-[95%] mx-auto block my-2 rounded-[20px] px-6 py-6"
                >
                  <div className="relative">
                    {data?.data_detail?.category ? <img
                      className="rounded-2xl"
                      src={`/dataImages/${data?.data_detail?.category} Data.jpg`}
                      alt="contract"
                    />
                      : <img
                        className="rounded-2xl"
                        src={`/dataImages/Multi Data.jpg`}
                        alt="contract"
                      />}
                    {/* <div className="bg-white dark:bg-slate-400 rounded-full h-8 w-8 absolute right-2 top-3 flex justify-center items-center text-[#856DA7]">
                      <FaRegHeart />
                    </div> */}
                  </div>
                  <div className="tracking-[-0.02em] leading-[30px] mt-4 ml-3 flex justify-between">
                    <div>
                      <h4 className="text-lg text-[#1B2559] dark:text-white font-bold">
                        {data.data_name}
                      </h4>
                      <p className="bg-white text-sm font-medium dark:bg-[#2B3674]">
                        Created Date : {data.orderDetails.createdAt.split("T")[0]}
                      </p>
                      <p className="bg-white text-sm font-medium dark:bg-[#2B3674]">
                        Listed Date : {data.createdAt.split("T")[0]}
                      </p>
                      <p className="bg-white text-sm font-medium dark:bg-[#2B3674]">
                        Price : {data.orderDetails.price? (parseInt(data.orderDetails.price)/1e18).toFixed(6) : 0} BNB
                      </p>
                      <p className="text-sm uppercase text-[#A3AED0]">
                        {data.data_type}
                      </p>
                    </div>
                    {/* <div className="w-24">
                      <img className="" src="img/Avatar.png" alt="Avatar" />
                    </div> */}
                  </div>
                  <div className="tracking-[-0.02em] text-sm leading-6 ml-3 mt-5 flex justify-between items-center">
                    <div>
                      <p className="text-[#856DA7] dark:text-blue-600 font-bold">
                        Size (KB) : {data.data_size}
                      </p>
                    </div>
                    {isConnected ? (
                      <BuyDataPackage
                        address={address}
                        orderId={data.orderDetails.order_id}
                        price={
                          parseInt(data.orderDetails.price) +
                          parseInt(data.orderDetails.fee)
                        }
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </>
            :null
          ))
        ) : (
          <p className="text-center my-10 font-semibold text-xl">
            No Package Found
          </p>
        )}
      </div>
    </>
  );
}
