import React, { useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import axios from "axios";
import { useAccount, useConnect } from "wagmi";
import { BuyerSellDataPackage } from "../contractIntreaction";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

export default function BuyerTableDP() {
  const dispatch = useDispatch();
  let auth = localStorage.getItem("auth");
  let name = localStorage.getItem("name");
  const [dataPackages, setDataPackages] = useState([]);
  const [currentDeleteId, setCurrentDeleteId] = useState("");
  const [reviewModal, setReviewModal] = useState(false);
  const [currentReviewId, setCurrentReviewId] = useState("");
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [showSellModal, setShowSellModal] = useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [errorMsg, setError] = useState("");
  const [currentPackageID, setCurrentPackageID] = useState("");
  const [priceInBnb, setPriceInBnb] = useState("");
  const [diroStatus, setDiroStatus] = useState(false);
  const [values, setValues] = React.useState({
    price: "",
    usdPrice: "",
    dataHash: "",
    signatureData: "",
    bnbPrice: "",
    count: 1,
  });

  const { address, connector, isConnected } = useAccount();
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect();

  const connectAndSign = async (x) => {
    await connect(x);
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    fetchBNBPrice();
  }, []);

  useEffect(() => {
    if (values.price !== "") {
      if (parseFloat(values.price) > 0) {
        const bnbPrice = parseFloat(values.price) / parseFloat(values.bnbPrice);
        if (!isNaN(bnbPrice)) setPriceInBnb(bnbPrice)
        else setPriceInBnb(0);
      } else setPriceInBnb(0);
    } else setPriceInBnb(0);
  }, [values.price]);

  const fetchBNBPrice = () => {
    // fetch('https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd')
    //   .then(response => response.json())
    //   .then(data => {
    //     const bnbPrice = parseFloat(data?.binancecoin?.usd);
    setValues({ ...values, "bnbPrice": 593.6 });
    // })
    // .catch(error => console.error('Error fetching BNB price:', error));
  };

  function ButtonList({ numberOfButtons }) {
    const buttons = [];

    for (let i = 0; i < numberOfButtons; i++) {
      buttons.push(<button onClick={() => pagination(i + 1)} className={`bg-[#11047A] text-white font-medium h-[30px] w-[30px] rounded-full ${page == i + 1 ? "opacity-25" : null} `} key={i}>{i + 1}</button>);
    }

    return (
      <>
        {buttons}
      </>
    );
  }

  const pagination = async (pageIndex) => {
    await getDataPackages(pageIndex);
    await setPage(pageIndex);
  }

  const getDataPackages = async (pageIndex = 1) => {
    if (auth) {
      await dispatch({ type: "loading", payload: true });
      await axios({
        method: "get",
        url: `https://web3backend.meinstein.ai/api/requested_data/get_with_paginate?page=${pageIndex}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: auth,
        },
      })
        .then(async (response) => {
          await setDataPackages(response.data.data.data);
          await setMaxPage(response.data.data.totalPage);
          await dispatch({ type: "loading", payload: false });
        })
        .catch(async (error) => {
          console.log(error.response);
          await dispatch({ type: "loading", payload: false });
        });
    }
  };

  const getDiroStatus = async () => {
    if (auth) {
      await dispatch({ type: "loading", payload: true });
      await axios({
        method: "get",
        url: `https://web3backend.meinstein.ai/api/auth/profile`,
        headers: {
          "Content-Type": "application/json",
          Authorization: auth,
        },
      })
        .then(async (response) => {
          console.log(response.data);
          if (response.data.data.diroStatus === "pending") {
            setDiroStatus(false);
          }
          await dispatch({ type: "loading", payload: false });
        })
        .catch(async (error) => {
          console.log(error.response);
          await dispatch({ type: "loading", payload: false });
        });
    }
  };

  const deleteDataPackages = async (dataId) => {
    if (auth) {
      await dispatch({ type: "loading", payload: true });
      await axios({
        method: "delete",
        url: `https://web3backend.meinstein.ai/api/requested_data/${dataId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: auth,
        },
      })
        .then(async (response) => {
          await getDataPackages(page);
          await setShowModal(false);
          await dispatch({ type: "loading", payload: false });
        })
        .catch(async (error) => {
          console.log(error.response);
          await dispatch({ type: "loading", payload: false });
        });
    }
  };

  useEffect(() => {
    getDataPackages();
    getDiroStatus();
  }, []);

  return (
    <>
      <div className="mt-[31px] mx-5">
        <p className="font-Poppins lg:text-xl text-base font-bold text-[#2B3674] dark:text-white text-end mr-5">
          Data Seller / Community
        </p>

        <div className="bg-white dark:bg-[#2B3674] w-full rounded-[20px] px-8 py-5 mt-10">
          <div className="flex justify-between items-center">
            <h3 className="text-2xl text-[#1B2559] dark:text-white font-bold">
              Data Contracts
            </h3>
          </div>

          <div className="mt-5 overflow-x-auto scroll-hide">
            <div className="border-b border-[#E9EDF7] flex items-center justify-between pb-2">
              <div className="min-w-[850px]">
                <div className="w-full flex justify-start text-[#A3AED0] tracking-[-0.02em] leading-6 text-sm font-medium ml-3">
                  <div className="flex items-center w-[22%]">
                    <p>Retention Period </p>
                    <FiChevronDown className="text-lg ml-2" />
                  </div>

                  <div className="flex items-center w-[18%]">
                    <p>Deletion Period </p>
                    <FiChevronDown className="text-lg ml-2" />
                  </div>

                  <div className="flex items-center justify-center w-[14%]">
                    <p>Type</p>
                    <FiChevronDown className="text-lg ml-2" />
                  </div>

                  <div className="flex items-center justify-center w-[19%]">
                    <p>Date From</p>
                    <FiChevronDown className="text-lg ml-2" />
                  </div>

                  <div className="flex items-center justify-center w-[12%]">
                    <p>Price Range</p>
                    <FiChevronDown className="text-lg ml-2" />
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:w-[1050px] w-[900px]">
              <table className="table-fixed border-separate border-spacing-y-2 w-full">
                <tbody className="text-[#2B3674] dark:text-white tracking-[-0.02em] leading-6 text-sm font-bold text-center">
                  {dataPackages.length ? (
                    <>
                      {dataPackages.map((data, index) => (
                        <tr key={index} className="flex items-center my-4">
                          <td className="w-[18%]">
                            {data.dataRetentionDuration}{" "}
                            {data.dataRetentionPeriod}
                          </td>
                          <td className="w-[19%]">
                            {data.dataDeletionDuration}{" "}
                            {data.dataDeletionPeriod}
                          </td>
                          <td className="w-[15%]">
                            {data.category.map((item, i) => (
                              <span key={i}>
                                {item.replace(" Data", "")}
                                {i !== data.category.length - 1 && " , "}
                              </span>
                            ))}
                          </td>
                          <td className="w-[12%]">
                            {data.createdAt.split("T")[0]}
                          </td>
                          <td className="w-[17%]">
                            {data.minPrice}$ - {data.maxPrice}$
                          </td>
                          <td className="w-[28%] flex space-x-2 items-center">
                            <button
                              className="bg-[#11047A] text-white font-medium h-[30px] w-[93px] rounded-full"
                              onClick={() => {
                                setCurrentReviewId(index);
                                setReviewModal(true);
                              }}
                            >
                              Preview
                            </button>
                            <button
                              className="bg-[#11047A] text-white font-medium h-[30px] w-[93px] rounded-full"
                              onClick={() => {
                                setCurrentPackageID(index);
                                setShowSellModal(true);
                              }}
                            >
                              List
                            </button>
                            <Link
                              to={`/order/edit/${data.id}`}
                              className="flex items-center justify-center bg-[#11047A] text-white font-medium h-[30px] w-[93px] rounded-full"
                            >
                              Edit
                            </Link>
                            <button
                              className="bg-red-600 text-white font-medium h-[30px] w-[93px] rounded-full"
                              onClick={() => {
                                setCurrentDeleteId(data.id);
                                setShowModal(true);
                              }}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <p className="text-center font-semibold py-6 w-full text-[#161319] dark:text-white">
                      No Data Package Found
                    </p>
                  )}
                </tbody>
              </table>
            </div>
            <div className="mt-5 flex justify-center space-x-3">
              <ButtonList numberOfButtons={maxPage} />
            </div>
          </div>
        </div>
      </div>
      {showSellModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none modalOuter">
            <div className="relative w-auto my-6 mx-auto max-w-sm">
              {/*content*/}
              <div className="border-0 w-[350px] text-black font-saira rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between py-2 px-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl mb-0 font-semibold">Buy</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowSellModal(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  {isConnected ? (
                    <div>
                      <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                        <label>Price (USD)*</label>
                        <input
                          type="number"
                          name="price"
                          onChange={handleChange}
                          className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        />
                        <p className="mt-3">Price in BNB: {priceInBnb ? (priceInBnb * values.count).toFixed(4) : 0}</p>
                      </div>
                      <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                        <label>Orders Count*</label>
                        <input
                          type="number"
                          name="count"
                          min='1'
                          value={values.count}
                          onChange={handleChange}
                          className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        />
                      </div>
                      {errorMsg ? (
                        <p className="text-sm text-center my-3 text-red-700 font-semibold">
                          {errorMsg}
                        </p>
                      ) : null}
                      {values.price > 0 && diroStatus ? (
                        <BuyerSellDataPackage
                          address={address}
                          price={priceInBnb ? priceInBnb.toFixed(6) : 0}
                          dataPackage={dataPackages[currentPackageID]}
                          setShowSellModal={setShowSellModal}
                          count={values.count}
                        />
                      ) : (
                        <button
                          type="button"
                          className="bg-[#11047A] text-white font-medium h-[30px] px-5 rounded-full"
                          onClick={() => {
                            if(!diroStatus) setError("Your DIRO verification is pending. You can't list data until it's approved.");
                            else setError("Please fill all the feilds.");
                            setTimeout(() => {
                              setError("");
                            }, 5000);
                          }}
                        >
                          List
                        </button>
                      )}
                    </div>
                  ) : (
                    <div className="flex justify-center items-center">
                      <>
                        {connectors
                          .filter((x) => x.ready && x.id !== connector?.id)
                          .map((x) => (
                            <>
                              {x.name === "MetaMask" ? (
                                <button
                                  type="button"
                                  className="bg-[#11047A] text-white font-medium h-[30px] px-5 rounded-full"
                                  key={x.id}
                                  onClick={async () => {
                                    await connectAndSign({ connector: x });
                                  }}
                                >
                                  Connect MetaMask
                                  {isLoading &&
                                    x.id === pendingConnector?.id &&
                                    " (connecting)"}
                                </button>
                              ) : null}
                            </>
                          ))}
                        {error ? (
                          <p className="text-sm text-center my-3 text-red-700 font-semibold">
                            {error}
                          </p>
                        ) : null}
                        {connectors.filter(
                          (x) => x.ready && x.id !== connector?.id
                        ).length ? null : (
                          <p className="text-sm text-center text-red-700 font-semibold">
                            <a
                              className="text-[#856DA7]"
                              href="https://metamask.io/download/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Install Metamask
                            </a>
                          </p>
                        )}
                      </>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-sm">
              <div className="border-0 w-[350px] rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-between py-2 px-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl mb-0 font-semibold">Are you sure want to delete?</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  <button
                    className="bg-red-600 text-white font-medium h-[30px] w-[93px] rounded-full"
                    onClick={() => {
                      deleteDataPackages(currentDeleteId);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {reviewModal && currentReviewId >= 0 ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-sm">
              <div className="border-0 w-[800px] rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-between py-2 px-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl mb-0 font-semibold">Preview</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setReviewModal(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <div className="relative p-6 flex-auto max-h-[70vh] overflow-y-scroll">
                  <div className="flex justify-center">
                    <img src="/img/logo.jpg" className="w-[120px] mb-3" alt="" />
                  </div>
                  <div className="mt-[31px] px-5 text-[#2B3674] dark:text-white mx-auto container">

                    <h1 className="text-[40px] my-5 text-center">DATA BUYING CONTRACT/AGREEMENT</h1>
                    <div className="mb-3">
                      <p>This Data Selling Agreement ("Agreement") is entered into as of {dataPackages[currentReviewId]?.effectiveDate.split("T")[0]}, by and between:</p>
                    </div>
                    <div className="mb-3">
                      <p>[Seller Name], a [Seller Jurisdiction] corporation, with its principal place of residence at [Seller Address] ("Seller"),</p>
                    </div>
                    <div className="mb-3">
                      <p>and</p>
                    </div>
                    <div className="mb-3">
                      <p>{dataPackages[currentReviewId]?.buyerCountry}, a {dataPackages[currentReviewId]?.buyerAddress} corporation, with its principal place of business at {dataPackages[currentReviewId]?.buyerAddress}.</p>
                    </div>
                    <div className="mb-3">
                      <p>mEinstein ("mEinstein", “mE” or "Marketplace") is hereby recognized as the facilitator platform for transactions between the Seller and the Buyer.</p>
                    </div>
                    <div className="mb-5">
                      <h3 className="mt-5 text-[30px]">1. DEFINITIONS</h3>
                      <p>1.1. "Data" refers to the information provided by Seller to Buyer under this Agreement.</p>
                      <p>1.2. "Effective Date" refers to the date this Agreement comes into force.</p>
                      <p>1.3. “Marketplace” refers to the online platform operated and managed by mEinstein, accessible via [https://marketplace.meinstein.ai/], and any associated mobile applications or services. The Marketplace serves as a digital intermediary facilitating the listing, discovery, and transaction of Data between Sellers and Buyers. It provides tools and services for these parties to connect, communicate, and conduct transactions within a structured framework set forth by mEinstein. The Marketplace is not a party to the transactions between Sellers and Buyers, nor does it assume liability for the quality, safety, legality, or accuracy of the Data exchanged, nor for the performance or conduct of any Seller or Buyer participating in transactions. mEinstein charges a fee for connecting Buyers and Sellers through the Marketplace, acting solely as a facilitator to enable these transactions. This fee structure is designed to maintain the operation and integrity of the Marketplace, without implying any responsibility for the transactions themselves or the Data exchanged therein.</p>
                    </div>
                    <div className="mb-5">
                      <h3 className="mt-5 text-[30px]">2. Marketplace Purpose</h3>
                      <p>The Parties acknowledge that mEinstein serves solely as a platform designed to facilitate the connection between Sellers and Buyers for the purpose of executing transactions related to data. mEinstein's role is limited to providing a marketplace environment and does not extend to any direct involvement in the transactions beyond this facilitation.</p>
                    </div>
                    <div className="mb-5">
                      <h3 className="mt-5 text-[30px]">3. Limitation of Liability</h3>
                      <p>mEinstein, its affiliates, and its service providers are not responsible for examining or evaluating the quality, authenticity, legality, or any other aspect of the data sold by Sellers or purchased by Buyers through the Marketplace. Consequently, mEinstein shall not be liable for any claims, disputes, damages, or losses that may arise from transactions or interactions initiated within the Marketplace environment. This limitation of liability extends to any issues related to the accuracy of listings, the ability of Sellers to sell data, or the ability of Buyers to pay for data.</p>
                    </div>
                    <div className="mb-5">
                      <h3 className="mt-5 text-[30px]">4. Dispute Resolution</h3>
                      <p>Any disputes arising between the Seller and the Buyer in connection with their transactions through the mEinstein Marketplace shall be resolved by the Parties through the Marketplace's dedicated "Disputes" section. The Parties agree to engage in good faith negotiations within this framework to resolve such disputes. mEinstein is not obligated to mediate or resolve disputes between Parties but may provide guidance or assistance in the dispute resolution process at its sole discretion.</p>
                      <div className="mb-5 ml-5">
                        <div className="mt-5">
                          <p className="font-semibold">4.1. Initial Dispute Notification:</p>
                          <ol className="list-disc">
                            <li><span className="font-semibold">4.1.1. </span> Buyer's Right to Dispute: Should the Buyer encounter issues regarding the quality, veracity, authenticity, or adherence to the terms of the contract of the Data purchased from the Seller, the Buyer is required to raise a dispute within forty-eight (48) hours following the completion of the transaction. This dispute must be lodged directly within the mEinstein Marketplace's designated dispute resolution system, clearly detailing the dispute's nature and providing any supportive evidence.</li>
                            <li><span className="font-semibold">4.1.2. </span> Seller's Right to Dispute: In cases where the Buyer, after receiving the Data, neither raises any issues within the designated forty-eight (48) hour period nor releases the funds from escrow, the Seller is granted the right to initiate a dispute through the mEinstein Marketplace. This action calls for an investigation into the Buyer's failure to comply with the transaction terms.</li>
                          </ol>
                        </div>
                        <div className="mt-5">
                          <p><span className="font-semibold">4.2. Escrow and Holding Period: </span> During the dispute resolution process, the transaction funds will be securely held in escrow by the mEinstein Marketplace or its designated financial intermediary. Accordingly, the Seller is restricted from withdrawing or receiving the funds, and the transaction is not considered complete for the Buyer until the dispute is duly resolved.</p>
                        </div>
                        <div className="mt-5">
                          <p><span className="font-semibold">4.3. Dispute Review Process: </span> Upon receipt of a dispute notification from either party, the mEinstein Marketplace will oversee a review process. This process aims to foster constructive dialogue between the Buyer and Seller, potentially incorporating mediation services to facilitate a mutually satisfactory resolution.</p>
                        </div>
                        <div className="mt-5">
                          <p className="font-semibold">4.4. Resolution and Fund Release:</p>
                          <ol className="list-disc">
                            <li><span className="font-semibold">4.4.1. </span> Automatic Release: Should neither party raise a dispute within the forty-eight (48) hour window, the funds held in escrow will be automatically released to the Seller.</li>
                            <li><span className="font-semibold">4.4.2. </span> Dispute Resolution: Following a dispute, the resolution process may lead to the amendment of the Data transaction, a partial or full refund, or another remedy agreed upon by both parties or as determined by the mEinstein Marketplace.</li>
                            <li><span className="font-semibold">4.4.3. </span> Seller's Rights upon Buyer's Non-Compliance: If the Buyer is found non-compliant in releasing the funds without a justified reason, the Seller is entitled to legally restrict the Buyer from using the Data. Furthermore, the contract between the Buyer and Seller will be considered null and void.</li>
                          </ol>
                        </div>
                        <div className="mt-5">
                          <p><span className="font-semibold">4.5. Legal Arbitration: </span> Should the mEinstein Marketplace's dispute resolution process fail to resolve the matter, either party may seek arbitration in accordance with the laws of [Seller Jurisdiction], to be held in a “Specified Location”. The arbitration decision shall be final and binding on both parties.</p>
                        </div>
                        <div className="mt-5">
                          <p><span className="font-semibold">4.6. Governing Law: </span> The dispute resolution process outlined herein is governed by the laws of [Seller Jurisdiction], regardless of its conflict of law principles.</p>
                        </div>
                        <div className="mt-5">
                          <p><span className="font-semibold">Costs of Dispute Resolution: </span> Each party shall bear its own costs incurred during the dispute resolution process. Expenses related to mediation or arbitration are to be equally shared by both parties, unless the arbitrator(s) rule otherwise.</p>
                        </div>
                      </div>
                    </div>
                    <div className="mb-5">
                      <h3 className="mt-5 text-[30px]">5. Exclusion of Liability for Third-Party Services</h3>
                      <p>The Parties acknowledge that mEinstein is not responsible for any loss, damage, breach of data, currency, or transactions that occur through the use of MetaMask, Web3, or any other third-party services integrated with the mEinstein Marketplace. The Parties are responsible for securing their digital wallets, transactions, and data, recognizing the inherent risks associated with digital and cryptographic technologies.</p>
                    </div>
                    <div className="mb-5">
                      <h3 className="mt-5 text-[30px]">6. Comprehensive Release</h3>
                      <p>The Parties hereby release mEinstein, its officers, directors, employees, partners, and agents from any claims, demands, and damages of every kind and nature arising out of or in any way connected with disputes between Parties, transactions in the Marketplace, and the use of third-party services like MetaMask and Web3. This release extends to any claim that might arise regarding the performance or non-performance of any Party in connection with the use of the mEinstein Marketplace.</p>
                    </div>
                    <div className="mb-5">
                      <h3 className="mt-5 text-[30px]">7. DATA SALE</h3>
                      <p>7.1. Seller agrees to sell and transfer to Buyer, and Buyer agrees to purchase from Seller, the Data according to the terms and conditions of this Agreement.</p>
                      <div className="mb-5 ml-5">
                        <div className="mt-5">
                          <p><span className="font-semibold">7.1. Quality Assurance: </span> The Seller guarantees that all Data sold through the mEinstein Marketplace adheres to the highest standards of quality, is accurate, up-to-date, and free from any defects. The Seller is responsible for ensuring that the Data complies with the description provided within the marketplace at the time of the sale.</p>
                        </div>
                        <div className="mt-5">
                          <p><span className="font-semibold">7.2. Timeliness of Delivery: </span> The Seller commits to delivering the Data to the Buyer within
                            {/* {Delivery Duration} */}
                            [Number] [days/weeks/months/years]  following the completion of the transaction. Any delays in delivery must be communicated to the Buyer with a revised delivery timeline as soon as such delays are apparent.</p>
                        </div>
                        <div className="mt-5">
                          <p><span className="font-semibold">7.3. Notification of Non-Compliance: </span> In the event the Buyer identifies that the delivered Data is not in compliance with the agreed standards of quality, accuracy, or timeliness, the Buyer shall notify the Seller within
                            {/* {Dispute Resolution}  */}
                            [Number] [days/weeks/months/years] of receipt of the Data as a “Dispute”. The notification must detail the aspects of non-compliance or inaccuracy.</p>
                        </div>
                        <div className="mt-5">
                          <p><span className="font-semibold">7.4. Remediation and Refund Policy:</span> Upon receiving a notification of non-compliance:</p>
                          <ol className="list-disc">
                            <li><span className="font-semibold">7.4.1. </span> The Seller is obliged to respond within 7 days, proposing a plan for remediation, which may include correcting inaccuracies, delivering missing data elements, or providing a revised dataset.</li>
                            <li><span className="font-semibold">7.4.2. </span>  If remediation is not feasible or the corrected Data is still not in compliance with the agreed terms, the Buyer is entitled to a full or partial refund, as mutually agreed upon or as determined by the mEinstein Marketplace's dispute resolution process.</li>
                          </ol>
                        </div>
                        <div className="mt-5">
                          <p><span className="font-semibold">7.5. Consequences of Non-Compliance: </span> Repeated instances of non-compliance by the Seller may result in penalties including, but not limited to, suspension or termination of the Seller's account on the mEinstein Marketplace.</p>
                        </div>
                      </div>
                    </div>
                    <div className="mb-5">
                      <h3 className="mt-5 text-[30px]">8. USE OF DATA</h3>
                      <p>8.1. Buyer agrees to use the Data solely for the purposes explicitly defined in this Agreement and not for any other purpose (purpose limitation).</p>
                      <p><span className="font-semibold">8.2. Permitted Uses:</span> The Buyer agrees to use the Data exclusively for the purposes outlined in the contract and within the bounds of applicable laws and regulations. Any deviation from the agreed-upon use must receive prior written consent from the Seller.</p>
                      <p><span className="font-semibold">8.3. Restrictions on Data Use:</span> The Buyer is prohibited from:</p>
                      <ol className="list-disc ml-5">
                        <li><span className="font-semibold">8.2.1. </span> Sharing, reselling, or distributing the Data without explicit written permission from the Seller.</li>
                        <li><span className="font-semibold">8.2.2. </span> Using the Data in any way that could be considered unlawful, unethical, or harmful to individuals or groups represented in the Data.</li>
                      </ol>
                      <p><span className="font-semibold">8.4. Data Security:</span> The Buyer commits to implementing reasonable security measures to protect the Data from unauthorized access, disclosure, alteration, or destruction.</p>
                      <p><span className="font-semibold">8.5. Notification of Misuse:</span> In the case of any unauthorized use, access, or security breach involving the Data, the Buyer must promptly notify the Seller and cooperate fully to remedy the situation.</p>
                      <p><span className="font-semibold">8.6. Consequences of Unauthorized Use: </span> Unauthorized use of the Data by the Buyer may result in immediate termination of the data use rights granted under this contract, in addition to any legal remedies available to the Seller.</p>
                      <p>8.7 Purpose(s) Limitations:</p>
                      <ul className="list-decimal ml-5">
                        <li><span className="font-semibold">Personal Data: </span> profile, career, activities & interests, social
                          <ol className="list-disc ml-5">
                            <li><span className="font-semibold">Initial Purpose: </span> Collecting personal information for creating user profiles and facilitating social interactions.</li>
                            <li><span className="font-semibold">Limitation: </span> The data should not be used for purposes unrelated to personalization and social interactions, such as targeted advertising, without explicit consent.</li>
                          </ol>
                        </li>
                        <li><span className="font-semibold">Family Data: </span> profile, activities & interests, social data
                          <ol className="list-disc ml-5">
                            <li><span className="font-semibold">Initial Purpose: </span> Gathering family-related information for creating a comprehensive family profile.</li>
                            <li><span className="font-semibold">Limitation: </span> The family data should not be used for purposes outside of family-related services or communications, such as individualized marketing, without explicit consent.</li>
                          </ol>
                        </li>
                        <li><span className="font-semibold">Financial Data: </span> assets, liabilities, income, credit cards, savings, spending, loans
                          <ol className="list-disc ml-5">
                            <li><span className="font-semibold">Initial Purpose: </span> Collecting financial information for managing and advising on financial matters.</li>
                            <li><span className="font-semibold">Limitation: </span> Financial data should not be used for purposes unrelated to financial management, such as third-party marketing or profiling, without explicit consent.</li>
                          </ol>
                        </li>
                        <li><span className="font-semibold">Wishlist/Shopping Data: </span>
                          <ol className="list-disc ml-5">
                            <li><span className="font-semibold">Initial Purpose: </span> Compiling a shopping list based on user preferences and needs.</li>
                            <li><span className="font-semibold">Limitation: </span> The shopping list data should not be used for purposes other than facilitating shopping, such as targeted advertising or data analytics, without explicit consent.</li>
                          </ol>
                        </li>
                        <li><span className="font-semibold">Car Maintenance Data: </span>
                          <ol className="list-disc ml-5">
                            <li><span className="font-semibold">Initial Purpose: </span> Collecting information about the car for maintenance, service, and customization purposes.</li>
                            <li><span className="font-semibold">Limitation: </span> Car profile data should not be used for purposes unrelated to car-related services, such as targeted advertising, without explicit consent.</li>
                          </ol>
                        </li>
                        <li><span className="font-semibold">Home Maintenance Data: </span> including amenities, appliances, and equipment.
                          <ol className="list-disc ml-5">
                            <li><span className="font-semibold">Initial Purpose: </span> Compiling a profile of the home for maintenance and automation purposes.</li>
                            <li><span className="font-semibold">Limitation: </span> Home profile data should not be used for purposes unrelated to home-related services, such as targeted advertising or data analytics, without explicit consent.</li>
                          </ol>
                        </li>
                        <li><span className="font-semibold">Vacation Data: </span> budget, trip preferences, activities and interests, dining & entertainment, frequency etc.
                          <ol className="list-disc ml-5">
                            <li><span className="font-semibold">Initial Purpose: </span> Collecting information for planning and customizing vacation experiences.</li>
                            <li><span className="font-semibold">Limitation: </span> Vacation profile data should not be used for purposes unrelated to vacation planning and services, such as targeted advertising or data resale, without explicit consent.</li>
                          </ol>
                        </li>
                        <li><span className="font-semibold">Daily Routine Data: </span> invaluable insights on consumer habits, encompasses a spectrum of activities that reflect a user's lifestyle, choices, and preferences, providing a window into consumer behavior.
                          <ol className="list-disc ml-5">
                            <li><span className="font-semibold">Initial Purpose: </span> forge stronger connections with their customers, improve their offerings, and compete effectively in a market that values personalization and user-centricity.
                              <ul className="list-decimal ml-5">
                                <li><span className="font-semibold">Personalization of Services: </span> Businesses can analyze daily routine data to personalize their offerings, ensuring that their services align with the timing, frequency, and preferences of users' daily activities.</li>
                                <li><span className="font-semibold">Product Development: </span> Insights gleaned from routine data can inform product development, enabling companies to create solutions that address the specific needs and gaps identified in a user's daily life.</li>
                                <li><span className="font-semibold">Targeted Marketing: </span> By understanding the day-to-day activities of users, businesses can craft marketing campaigns that resonate with the user's lifestyle, increasing engagement and conversion rates.</li>
                                <li><span className="font-semibold">Customer Experience Enhancement: </span> Daily routine data can help businesses optimize the customer journey by predicting needs and providing timely interventions or recommendations that enhance the user experience.</li>
                                <li><span className="font-semibold">Operational Efficiency: </span> Routine data can inform businesses about peak usage times and patterns, allowing for better resource allocation and operational planning.</li>
                              </ul>
                            </li>
                            <li><span className="font-semibold">Limitation: </span>
                              <ul className="list-decimal ml-5">
                                <li><span className="font-semibold">Compliance with Data Usage: </span> ensure that all data transactions respect user consent, transparent, mutually beneficial and comply with relevant data protection regulations. </li>
                                <li><span className="font-semibold">Ethical Considerations: </span> Businesses are reminded to use daily routine data in ways that respect the privacy and autonomy of users, adhering to the ethical standards set by mEinstein and the broader community.</li>
                              </ul>
                            </li>
                          </ol>
                        </li>
                        <li><span className="font-semibold">Online Purchases: </span>
                          <ol className="list-disc ml-5">
                            <li><span className="font-semibold">Initial Purpose: </span> Collecting customer information for processing and fulfilling online orders.</li>
                            <li><span className="font-semibold">Limitation: </span> The collected data should not be used for unrelated purposes, such as marketing unrelated products, without obtaining additional consent.</li>
                          </ol>
                        </li>
                        <li><span className="font-semibold">Healthcare Records: </span>
                          <ol className="list-disc ml-5">
                            <li><span className="font-semibold">Initial Purpose: </span> Gathering patient information for medical diagnosis and treatment.</li>
                            <li><span className="font-semibold">Limitation: </span> The medical data should not be used for non-health-related purposes, such as marketing, without explicit consent.</li>
                          </ol>
                        </li>
                        <li><span className="font-semibold">Employment Applications: </span>
                          <ol className="list-disc ml-5">
                            <li><span className="font-semibold">Initial Purpose: </span> Collecting personal information for the purpose of evaluating and hiring job applicants.</li>
                            <li><span className="font-semibold">Limitation: </span> The collected data should not be used for other purposes, such as third-party marketing or background checks, without proper consent.</li>
                          </ol>
                        </li>
                        <li><span className="font-semibold">Social Media Platforms: </span>
                          <ol className="list-disc ml-5">
                            <li><span className="font-semibold">Initial Purpose: </span> User data collected for creating and managing social media accounts.</li>
                            <li><span className="font-semibold">Limitation: </span> The data should not be used for purposes not disclosed to users during account creation, such as selling personal information to advertisers without explicit consent.</li>
                          </ol>
                        </li>
                        <li><span className="font-semibold">Financial Transactions: </span>
                          <ol className="list-disc ml-5">
                            <li><span className="font-semibold">Initial Purpose: </span> Collecting customer financial information for processing transactions.</li>
                            <li><span className="font-semibold">Limitation: </span> Financial data should not be used for unrelated purposes, such as profiling customers for targeted advertising, without obtaining separate consent.</li>
                          </ol>
                        </li>
                        <li><span className="font-semibold">Market Research Surveys: </span>
                          <ol className="list-disc ml-5">
                            <li><span className="font-semibold">Initial Purpose: </span> Collecting opinions and preferences of individuals for market research.</li>
                            <li><span className="font-semibold">Limitation: </span> The data should not be used for purposes outside the scope of market research, such as sharing individual responses with unrelated third parties without consent.</li>
                          </ol>
                        </li>
                        <li><span className="font-semibold">Government Census: </span>
                          <ol className="list-disc ml-5">
                            <li><span className="font-semibold">Initial Purpose: </span> Gathering demographic information for statistical analysis and government planning.</li>
                            <li><span className="font-semibold">Limitation: </span> Census data should not be used for purposes such as individual profiling or targeted marketing without explicit consent.</li>
                          </ol>
                        </li>
                        <li><span className="font-semibold">Subscription Services: </span>
                          <ol className="list-disc ml-5">
                            <li><span className="font-semibold">Initial Purpose: </span> Collecting customer information for providing a subscription service.</li>
                            <li><span className="font-semibold">Limitation: </span> The collected data should not be used for purposes unrelated to the subscription service, such as selling personal information to third parties without consent.</li>
                          </ol>
                        </li>
                        <li><span className="font-semibold">Educational Institutions: </span>
                          <ol className="list-disc ml-5">
                            <li><span className="font-semibold">Initial Purpose: </span> Collecting student information for academic and administrative purposes.</li>
                            <li><span className="font-semibold">Limitation: </span> Student data should not be used for purposes unrelated to education, such as marketing commercial products, without consent.</li>
                          </ol>
                        </li>
                      </ul>
                      <p><span className="font-semibold">8.8. Third-Party Disclosure: </span> The Buyer shall not disclose, share, or transfer any Data acquired from the Seller to any third party without obtaining prior written consent from the Seller. Such consent, if granted, shall specify the conditions under which the Data may be shared, including limitations on the third-party's use of the Data to ensure it aligns with the original purpose for which the Data was sold. The Buyer must ensure that any approved third party adheres to the same data protection and usage standards as outlined in this Agreement.</p>
                    </div>
                    <div className="mb-5">
                      <h3 className="mt-5 text-[30px]">9. COMPLIANCE WITH LAWS</h3>
                      <p>9.1. Both parties agree to comply with all applicable laws and regulations regarding the collection, use, and transfer of personal data.</p>
                      <p><span className="font-semibold">9.2. Legal Compliance: </span> Both Seller and Buyer agree to comply with all applicable local, national, and international laws and regulations regarding data protection, privacy, and usage. This includes, but is not limited to, the General Data Protection Regulation (GDPR) for transactions involving European Union citizens, the California Consumer Privacy Act (CCPA) for transactions involving California residents, and any other relevant data protection laws.</p>
                      <p><span className="font-semibold">9.3. Industry-Specific Regulations: </span> In addition to general data protection laws, the Parties agree to adhere to any industry-specific regulations that may apply to the use of the Data, ensuring compliance with standards and practices relevant to their respective fields.</p>
                    </div>
                    <div className="mb-5">
                      <h3 className="mt-5 text-[30px]">10. DATA SECURITY</h3>
                      <p>10.1. Buyer agrees to implement and maintain reasonable security measures to protect the Data from unauthorized access, disclosure, or use.</p>
                      <p><span className="font-semibold">10.2. Security Measures: </span> The Buyer agrees to implement and maintain robust security measures to protect the Data against unauthorized access, disclosure, alteration, or destruction. This includes employing data encryption technologies during data transmission and storage, implementing access controls to ensure only authorized personnel can access the Data, and conducting periodic security assessments to identify and mitigate potential vulnerabilities.</p>
                    </div>
                    <div className="mb-5">
                      <h3 className="mt-5 text-[30px]">11. DATA ACCURACY</h3>
                      <p>11.1. Seller warrants that the Data provided is accurate and up-to-date to the best of its knowledge.</p>
                      <p><span className="font-semibold">11.2. Accuracy Warranty: </span> The Seller warrants that the Data provided under this Agreement is accurate and up-to-date to the best of their knowledge. In the event of any inaccuracies or errors in the Data, the Seller agrees to promptly correct such inaccuracies upon notification by the Buyer, within a reasonable timeframe not exceeding 10 days from the date of notification.</p>
                    </div>
                    <div className="mb-5">
                      <h3 className="mt-5 text-[30px]">12. DATA RETENTION AND DELETION</h3>
                      <p>12.1. Buyer shall retain the Data only for the duration necessary to fulfill the purposes defined in this Agreement and shall securely delete or return the Data to Seller after the agreed-upon retention period.</p>
                      <p>12.2. Buyer shall retain the Data for a period not exceeding  {dataPackages[currentReviewId]?.dataRetentionDuration} {dataPackages[currentReviewId]?.dataRetentionPeriod} from the Effective Date, solely for the purposes outlined in Section 2. After the retention period elapses, Buyer shall promptly and securely delete within  {dataPackages[currentReviewId]?.dataDeletionDuration} {dataPackages[currentReviewId]?.dataDeletionPeriod} or, at Seller's discretion, return all copies of the Data to Seller. Seller may request the deletion of specific data prior to the expiration of the retention period upon written notice to Buyer.</p>
                      <p><span className="font-semibold">12.3. Deletion Procedure: </span> Upon the expiration of the retention period or upon the Seller's request, the Buyer shall securely delete or return all copies of the Data to the Seller. The Buyer must provide written certification to the Seller confirming the deletion or return of the Data.</p>
                      <p><span className="font-semibold">12.4. Legal Requirements: </span> The Buyer acknowledges that certain jurisdictions may impose specific legal requirements on data storage. In such cases, the Buyer agrees to comply with these legal requirements regarding the retention and deletion of Data.</p>
                    </div>
                    <div className="mb-5">
                      <h3 className="mt-5 text-[30px]">13. INDEMNIFICATION</h3>
                      <p>13.1. Buyer agrees to indemnify and hold Seller harmless from any claims, damages, or liabilities arising out of or related to the use or misuse of the Data by Buyer.</p>
                      <p>13.2 The Seller and the Buyer each agree to indemnify, defend, and hold harmless mEinstein and its affiliates, officers, agents, employees, and licensors from any claim, demand, loss, liability, or expense, including attorneys' fees, arising out of or related to their use of the Marketplace, their transactions, any breach of this Contract, or their violation of any law or the rights of a third-party</p>
                    </div>
                    <div className="mb-5">
                      <h3 className="mt-5 text-[30px]">14. TERMINATION</h3>
                      <p>14.1. Either party may terminate this Agreement upon a material breach by the other party, provided that the breaching party is given written notice and an opportunity to cure within {dataPackages[currentReviewId]?.contractTerminationDuration} {dataPackages[currentReviewId]?.contractTerminationPeriod}.</p>
                      <p><span className="font-semibold">14.2. Termination Conditions: </span> This Agreement may be terminated by either party upon giving {dataPackages[currentReviewId]?.contractTerminationDuration} {dataPackages[currentReviewId]?.contractTerminationPeriod} written notice to the other party under the following conditions:</p>
                      <ol className="list-decimal">
                        <li><span className="font-semibold">Breach of Agreement:  </span> If either party breaches any provision of this Agreement and fails to remedy such breach within 30 days after receiving written notice of the breach.</li>
                        <li><span className="font-semibold">Insolvency: </span>  If either party becomes insolvent, declares bankruptcy, or is otherwise unable to meet its financial obligations.</li>
                        <li><span className="font-semibold">Mutual Agreement: </span>  If both parties mutually agree to terminate the Agreement for any reason.</li>
                      </ol>
                      <p><span className="font-semibold">14.3. Consequences of Termination: </span></p>
                      <ol className="list-disc">
                        <li><span className="font-semibold">Data Return or Deletion: </span> Upon termination, the Buyer must either return or delete (at the Seller's discretion) all Data received from the Seller, except as required to be retained by law. The Buyer must provide a written certification to the Seller confirming the deletion or return of the Data.</li>
                        <li><span className="font-semibold">Confidential Information: </span> Both parties must return or destroy all confidential information obtained from the other party during the term of the Agreement and certify in writing that all such information has been returned or destroyed, except for copies that are required to be retained by law.</li>
                      </ol>
                      <p><span className="font-semibold">14.4. Survival: </span> Sections pertaining to confidentiality, data security, compliance with laws, and any other provisions which by their nature should survive termination, shall continue in full force and effect following the termination of this Agreement.</p>

                    </div>
                    <div className="mb-5">
                      <h3 className="mt-5 text-[30px]">15. Confidentiality</h3>
                      <p>Both parties agree to maintain the confidentiality of the Agreement and any confidential information exchanged between them during the term of this Agreement and for a period of 3 years after its termination. Confidential information includes, but is not limited to, trade secrets, business plans, customer lists, and any other information deemed proprietary by either party. Neither party shall disclose any confidential information to third parties without the prior written consent of the other party, except as required by law.</p>
                    </div>
                    <div className="mb-5">
                      <h3 className="mt-5 text-[30px]">16. Amendments</h3>
                      <p><span>Amendment Procedure: </span> Any amendments or modifications to this Agreement must be made in writing and signed by both parties. No oral modification or amendment shall be considered valid. Both parties agree to negotiate any amendments in good faith, recognizing that changes may be necessary to reflect evolving legal, technical, or business developments.</p>
                    </div>
                    <div className="mb-5">
                      <h3 className="mt-5 text-[30px]">17. Governing Law and Jurisdiction</h3>
                      <p>10.1. This Agreement shall be governed by and construed in accordance with the laws of [Seller Jurisdiction]. Any disputes arising under or in connection with this Agreement shall be resolved in the courts of [Seller Jurisdiction].</p>
                      <p>I hereby authorize this electronic signature submittal to serve as my legal signature.</p>
                      <p>The Seller</p>
                      <p>The Buyer</p><br />
                      <p>[Seller's Name/Digital Signature]</p>
                      <p>Date</p><br />
                      <p>{name}</p>
                      <p>Date: {dataPackages[currentReviewId]?.createdAt.split("T")[0]}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-60  fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
