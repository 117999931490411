import React, { useState } from "react";
import { MdNightlight } from "react-icons/md";
import { BiShow, BiHide } from "react-icons/bi";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export default function ForgetPassword(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = React.useState({
    email: "",
  });
  const [errorMsg, setError] = useState("");

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const forgetPassword = async (e) => {
    e.preventDefault();
    if (values.email) {
      await dispatch({ type: "loading", payload: true });
      await axios({
        method: "post",
        url: `https://web3backend.meinstein.ai/api/auth/forget_password`,
        data: {
          email: values.email,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.data.data.status) {
            await dispatch({ type: "loading", payload: false });
            await navigate("/");
          }
        })
        .catch(async (error) => {
          if (error.response.data?.errorMessage?.message)
            setError(error.response.data?.errorMessage?.message);
          else setError("Invalid User");
          await dispatch({ type: "loading", payload: false });
          setTimeout(() => {
            setError("");
          }, 5000);
        });
    } else setError("Please fill all the feilds");
  };

  return (
    <>
      <div className="bg-white dark:bg-[#2B3674] font-DMSans">
        <div className="flex h-[95vh] bg-[url('img/background.png')] bg-cover lg:bg-none">
          <div className="w-11/12 sm:w-3/4 mx-auto lg:w-1/2">
            <section className="h-[96%] lg:h-full flex flex-col justify-center lg:block">
              <div className="px-6 h-5/6 sm:h-2/3 lg:h-[96%] text-gray-800">
              <img
                  src="/img/logo.jpg"
                  alt=""
                  className="w-[120px] mt-10 rounded-2xl"
                />
                <div className="flex justify-center items-center flex-wrap h-[75%] g-6 bg-white dark:bg-[#2B3674] rounded-tl-[90px] rounded-br-[90px] lg:bg-transparent lg:rounded-none">
                  <div className="sm:w-[410px]">
                    <form>
                      <div className="flex flex-col items-center justify-center lg:block">
                        <p className="text-2xl sm:text-[36px] sm:w-full font-bold text-[#2B3674] dark:text-white mb-8">
                          Forget Password
                        </p>

                        {/* <!-- Email input --> */}
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label>Email*</label>
                          <input
                            type="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="mail@simmmple.com"
                            autoComplete="off"
                          />
                        </div>

                        {errorMsg ? (
                          <p className="text-sm text-center text-red-700 font-semibold">
                            {errorMsg}
                          </p>
                        ) : null}

                        <div className="w-full text-center text-xs sm:text-sm">
                          <button
                            type="button"
                            onClick={forgetPassword}
                            className="flex justify-center items-center w-full tracking-[-0.02em] font-bold text-white bg-[#856DA7] rounded-2xl py-5 sm:my-5"
                          >
                            Forget Password
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="hidden lg:flex flex-col items-center justify-center bg-cover lg:bg-[url('img/background.png')] rounded-[30px] mr-2 w-1/2 lg:space-y-10 space-y-7 text-gray-200 relative">
              <p className="w-3/4 font-extrabold text-center text-5xl leading-[60px] tracking-wide">
                THE NEW WAY TO CONNECT WITH CONSUMERS
              </p>
              <p className="w-3/4 font-light text-center text-2xl absolute bottom-24 uppercase">
                Purchase consent-based data & intelligence directly from
                consumers.
              </p>
            </div>

          <button className="fixed bottom-16 z-50 right-7" onClick={() => props.setThemeToggle(!props.themeToggle)}>
            <div className="theme_toggler p-3 text-white -rotate-45">
              <MdNightlight className="text-sm sm:text-xl" />
            </div>
          </button>
        </div>
      </div>
    </>
  );
}
